<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('forms.add_new') }} Partner
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- ID -->
          <validation-provider
            #default="validationContext"
            name="ID"
            rules="required"
            :custom-messages="{ required: $t('validators.required') }"
          >
            <b-form-group
              label="ID"
              label-for="id"
            >
              <b-form-input
                id="id"
                v-model="recordData.id"
                autofocus
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Description -->
          <validation-provider
            #default="validationContext"
            name="Description"
            rules="required"
            :custom-messages="{ required: $t('validators.required') }"
          >
            <b-form-group
              :label="$t('resources.description')"
              label-for="description"
            >
              <b-form-input
                id="description"
                v-model="recordData.description"
                autofocus
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Field: Total Licenses -->
          <validation-provider
            #default="validationContext"
            :name="$t('dash.cards.total_licences')"
            rules="min:0|integer"
          >
            <b-form-group
              :label="$t('dash.cards.total_licences')"
              label-for="total_licenses"
            >
              <b-form-input
                id="total_licenses"
                v-model="recordData.total_licenses"
                :state="getValidationState(validationContext)"
                type="number"
                min="0"
              />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
          </validation-provider>

          <!-- Support Email -->
          <validation-provider
            #default="validationContext"
            name="Support Email"
            rules="required"
            :custom-messages="{ required: $t('validators.required') }"
          >
            <b-form-group
              :label="$t('fields.support_email')"
              label-for="support_email"
            >
              <b-form-input
                id="support_email"
                v-model="recordData.support_email"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>


          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t('forms.add') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t('forms.cancel') }}
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { createPartner } from '@/api/routes'

import {
  BInputGroup, BInputGroupAppend,
  BSidebar,BMedia, BForm, BCol, BFormDatepicker, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormCheckbox
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, min,integer} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BInputGroup, BInputGroupAppend,
    BMedia,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormCheckbox,
    BFormDatepicker, 
    BButton,
    BCol,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewSidebarActive',
    event: 'update:is-add-new-sidebar-active',
  },
  props: {
    isAddNewSidebarActive: {
      type: Boolean,
      required: true,
    },

  },
  created(){
    extend('required', {
      ...required,
      message: this.$t('forms.errors.required'),
    });
    extend('min', {
      ...min,
      message: (field, { length }) => this.$t('forms.errors.num_min', { length })
    });
    extend('integer', {
      ...integer,
      message: this.$t('forms.errors.integer'),
    });
  },
  data() {
    return {
      required,
      min, 
      integer,
    }
  },
  setup(props, { emit }) {
    const blankrecordData = {
      id: '',
      description: '',
      total_licenses: '',
      support_email: '',
    }

    const recordData = ref(JSON.parse(JSON.stringify(blankrecordData)))
    const resetrecordData = () => {
      recordData.value = JSON.parse(JSON.stringify(blankrecordData))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetrecordData)


    return {
      recordData,

      // form validation
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
  methods:{
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
    async onSubmit(){
      // console.log(this.recordData, "submit")
      await this.$http.post(createPartner, this.recordData).then( response => {
        // console.log(response)
        if(response.data.status == 200){
          this.makeToast('success', this.$t('Success'), this.$t('forms.save_success'));
          this.$emit('refetch-data')
          this.$emit('update:is-add-new-sidebar-active', false)
        } else {
          this.makeToast('danger', this.$t('Error'), response.data.msg);
        }
      }).catch(error => {
        // console.log("Err -> ", error);
        this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
      })
    }
  },
}
</script>

<style lang="scss">

</style>
